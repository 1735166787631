jQuery(document).ready(function($) {
    var check_brochure_form = 0;
    var opt_modal_redirection_ip = Number(siteConfig.optModalRedirectionIp) || 0;
    var autoRedirectionIp = Number(siteConfig.autoRedirectionIp) || 0;
    //var apiURL = "https://api.ipgeolocation.io/ipgeo?fields=country_code2,calling_code,country_name";
    var apiURL = "https://avgtravels.com/api/geoip2/";
    var options = { mode: 'cors', headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' } };

    if (window.location.href.includes("localhost")) {
        // Lấy base URL từ location
        var baseURL = window.location.origin + window.location.pathname.split('/').slice(0, 4).join('/');
        apiURL = baseURL + "/api/geoip2/"
        options = {};
    }

    // Thực hiện gọi API để lấy dữ liệu địa chỉ IP
    const ipgeoAPIPromise = fetch(apiURL, options)
        .then(res => res.json())
        .catch(error => console.log('Error:', error));

    // Kiểm tra và thực hiện chuyển hướng tự động
    function handleAutoRedirect(data) {
        var clientCountry = data.country_code2;
        var currentSite = siteRegion;
        var redirectURLs = siteConfig.redirectURLs;

        if (!new URL(document.location).searchParams.has("region")) {
            if (clientCountry === "AU" && currentSite !== "au") {
                window.location.href = redirectURLs.au;
            } else if (clientCountry === "NZ" && currentSite !== "nz") {
                window.location.href = redirectURLs.nz;
            } else if (clientCountry !== "AU" && clientCountry !== "NZ" && currentSite !== "us") {
                window.location.href = redirectURLs.us;
            }
        }
    }

    // Kiểm tra và hiển thị popup chuyển hướng
    function setupRedirectionPopup(data) {
        var clientCountry = data ? .country_code2 || 'AU';
        var clientCountryName = data.country_name;

        $(".select-region-menu-container li a").off().on("click", function(e) {
            e.preventDefault();
            let clickedRegionCountry = $(this).attr('data-region');
            let clickedRegionCountryName = $(this).text();
            var urlSite = $(this).attr('href');
            if (shouldShowRedirectionPopup(clientCountry, clickedRegionCountry)) {
                showRedirectionPopup(clientCountryName, clickedRegionCountryName, urlSite);
            } else {
                window.location.href = urlSite;
            }
        });
    }

    // Điều kiện hiển thị popup
    function shouldShowRedirectionPopup(clientCountry, clickedRegionCountry) {
        return clickedRegionCountry !== clientCountry && ['VN', 'AU', 'NZ', 'US'].includes(clientCountry);
    }

    // Hiển thị popup
    function showRedirectionPopup(clientCountryName, clickedRegionCountryName, urlSite) {
        $('#popup-redirection-ip .modal-title').html('You appear to be from ' + clientCountryName + '');
        $('#popup-redirection-ip .modal-body').html('<p>We see that you are based in ' + clientCountryName + ' but you are viewing a page that is meant for ' + clickedRegionCountryName + ' residents.</p><p>Would you like to be redirected to the corresponding page for ' + clientCountryName + '</p>');
        $('#popup-redirection-ip').modal('show');

        $('#popup-redirection-ip button.yes').off().on('click', function() {
            window.location.href = urlSite;
        });

        $('#popup-redirection-ip button.no, #popup-redirection-ip button.close').off().on('click', function() {
            hideRedirectionPopup();
        });
    }

    // Ẩn popup
    function hideRedirectionPopup() {
        $('#popup-redirection-ip').modal('hide');
    }

    if (autoRedirectionIp == 1) {
        ipgeoAPIPromise.then(handleAutoRedirect);
    } else if (opt_modal_redirection_ip === 1) {
        ipgeoAPIPromise.then(data => {
            setupRedirectionPopup(data);
        });
    }

    if($('#popup-region-selection').length > 0) {
        $('#popup-region-selection').modal('show');   
    }
});